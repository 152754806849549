import { PageProps } from 'gatsby';
import { EspaceData } from 'packages/innedit';
import React, { FC } from 'react';

import CMSView from '~/components/View';
import Form from '~/containers/Admin/Form';
import params from '~/params/espace.json';
import TemplateAdmin from '~/templates/admin';
import requireUser from '~/utils/requireUser';

const PageAdminEspacesCreate: FC<PageProps> = () => (
  <TemplateAdmin>
    <CMSView>
      <Form model={new EspaceData({ params })} name="espace" type="create" />
    </CMSView>
  </TemplateAdmin>
);

export default requireUser(PageAdminEspacesCreate, 'admin');
